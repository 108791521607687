// const authorizedUsers = ["mayowalli@yahoo.co.uk", "tflux2011@gmail.com"];

const authorizedUsers = [
  "mayowalli@yahoo.co.uk",
  "michael@tractionapps.co",
  "anjola@tractionapps.co",
  "jegede@tractionapps.co",
  "demilade@tractionapps.co",
  "tflux2011@gmail.com",
  "olumide@tractionapps.co",
  "abosede@tractionapps.co",
  "dolapo@tractionapps.co",
];
export default authorizedUsers;
